import React from 'react'
import styles from './ContactUs.module.css'
import {ReactComponent as PhoneIcon} from 'assets/icons/phoneIcon.svg'
import {ReactComponent as MessageIcon} from 'assets/icons/messageIcon.svg'

import {ReactComponent as FacebookFooterIcon} from 'assets/icons/facebookIcon.svg'
import {ReactComponent as TwitterFooterIcon} from 'assets/icons/twitterIcon.svg'
import {ReactComponent as InstagramFooterIcon} from 'assets/icons/instgramIcon.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
function ContactUs() {
  const {t} =useTranslation()
  return (
    <section className={styles['contact-us']}>
        <div className={styles['contact-us__wrapper']}>
            <div className={styles['contact-us__content-wrapper']}>
                <div className={styles['contact-us__content']}>
                  <h2 className={styles['contact-us__title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('technical_support_department')}</h2>
                  <p className={styles['contact-us__para-1']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('you_can_always_contact')}  </p>
                  <p className={styles['contact-us__para-2']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('contact_us_through')}</p>
                  <div onClick={()=>{window.open(`tel:+971505045003`, '_self')}} className={styles['contact-us__para-3']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                    <PhoneIcon/>
                    + 971 50 504 5003
                  </div>
                  <p className={styles['contact-us__para-4']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>24/7</p>
                  <p className={styles['contact-us__para-5']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('during_the_month')}</p>
                  <p className={styles['contact-us__para-6']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('send_a_message')}</p>
                  <div className={styles['contact-us__para-8']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                  info@ready-uae.com
                    <MessageIcon/>
                  </div>
                  <p className={styles['contact-us__para-7']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('contact_us_via')}</p>
                  <ul className={styles["footer__copyright-list"]} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <FacebookFooterIcon className={styles["footer__copyright-social"]}></FacebookFooterIcon>
                        </Link>
                    </li>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <TwitterFooterIcon className={styles["footer__copyright-social"]}/>
                        </Link>
                    </li>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <InstagramFooterIcon className={styles["footer__copyright-social"]}/>
                        </Link>
                    </li>
                  </ul>
                </div>
            </div>
            <div className={styles['contact-us__form-wrapper']}>
              <h2 className={styles['contact-us__form-title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('connect_with_us')}</h2>
              <p className={styles['contact-us__form-description']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('department_of_receiving')}</p>
              <form className={styles['contact-us__form']}>
                <input type='email' className={styles['contact-us__form-input']} placeholder={t('email')} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'/>
                <textarea className={styles['contact-us__form-input--textarea']} placeholder={t('message')} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'></textarea>
                <button className={styles['contact-us__form-button']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('send')}</button>
              </form>
            </div>
        </div>
    </section>
  )
}

export default ContactUs