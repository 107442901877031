import React, { useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Container } from 'react-bootstrap'
import styles from './TermsAndConditions.module.css'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
function TermsAndConditions() {
  const {t} =useTranslation()
  useEffect(()=>{
    AOS.refreshHard();
  },[])
  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        <MetaTags>
              <title>{t('emirates_fish')}</title>
        </MetaTags>
        <section className={styles['terms']}>
          <Container>
            <h1>{t('terms1')}</h1>
            <p>{t('terms2')}</p>
            <p>{t('terms3')}</p>
            <p>{t('terms4')}</p>
            <p>{t('terms5')}</p>
            <h2>{t('terms6')}</h2>
            <p>{t('terms7')}</p>
            <h2>{t('terms8')}</h2>
            <ul>
              <li>{t('terms9')}</li>
              <li>{t('terms10')}</li>
              <li>{t('terms11')}</li>
            </ul>
            <h2>{t('terms12')}</h2>
            <ul>
              <li>{t('terms13')}</li>
              <li>{t('terms14')}</li>
              <li>{t('terms15')}</li>
              <li>{t('terms16')}</li>
              <li>{t('terms17')}</li>
              <li>{t('terms18')}</li>
              <li>{t('terms19')}</li>
            </ul>
            <h2>{t('terms20')}</h2>
            <ul>
              <li>{t('terms21')}</li>
              <li>{t('terms22')}</li>
              <li>{t('terms23')}</li>
              <li>{t('terms24')}</li>
              <li>{t('terms25')}</li>
              <li>{t('terms26')}</li>
              <li>{t('terms27')}</li>
            </ul>
            <h2>{t('terms28')}</h2>
            <p>{t('terms29')}</p>
          </Container>
        </section>
    </motion.div>
    </>
  )
}

export default TermsAndConditions