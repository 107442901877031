import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './Header.module.css'
import {ReactComponent as AndroidIcon} from 'assets/icons/androidIcon.svg'
import {ReactComponent as AppleIcon} from 'assets/icons/appleIcon.svg'
import HeaderImgCenter from 'assets/imgs/headerImg1.png'
import HeaderImgLeft from 'assets/imgs/headerImg33.png'
import HeaderImgRight from 'assets/imgs/headerImg22.png'
import { useTranslation } from 'react-i18next'
function Header() {
  const {t} =useTranslation ()
  return (
    <header id='header'>
        <Container>
            <div className={styles['header__wrapper']} >
                <h1 className={styles['header__title']} data-aos='fade-up' data-aos-duration='800' data-aos-once='true'>{t('emirates_fish')}</h1>
                <h2 className={styles['header__sub-title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>{t('from_the_sea_to_your_door')}</h2>
                <div className={styles['header__social-wrapper']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='300' data-aos-once='true'>
                    <a href="https://play.google.com/store/apps/details?id=com.readyuae" target="_blank" className={styles['header__social-button']}> {t('the_app_for_android')}<AndroidIcon className={styles['header__social-icon']}/></a>
                    <a href="https://apps.apple.com/ae/app/ready-uae/id1661297820" target="_blank" className={styles['header__social-button']}> {t('the_app_for_iPhone')}<AppleIcon className={styles['header__social-icon']}/></a>
                </div>
                <div className={styles['header__img-wrapper']} >
                    <img src={HeaderImgLeft} alt='header image' className={styles['header__img']}/>
                    <img src={HeaderImgCenter} alt='header image' className={`${styles['header__img']} ${styles['header__img--relative']}`}/>
                    <img src={HeaderImgRight} alt='header image' className={styles['header__img']}/>
                </div>
            </div>
        </Container>
    </header>
  )
}

export default Header