import React, { useEffect } from 'react'
import styles from './SideBar.module.css'
import { NavLink, useNavigate } from 'react-router-dom'
import {ReactComponent as FishLogo} from 'assets/icons/readyLogo.svg'
import { useTranslation } from 'react-i18next'
import './SideBar.css'
function SideBar({isSideBarVisible,toggleSideNavBar}) {
    const {t} =useTranslation()
    const navigate =useNavigate()

    useEffect(()=>{
        toggleSideNavBar()
    },[navigate])
    return (
    <nav className={`${styles["side-navbar"]} ${isSideBarVisible && styles['side-navbar--open']}`}>
        <div className={styles["side-navbar__content"]}>
            <div className='d-flex mb-3'>
                <NavLink to='/' className='m-auto'><FishLogo className={styles['navbar__logo']}/></NavLink>
            </div>
            <ul className={styles["side-navbar__nav-list"]}>
                <li className={`${styles["side-navbar__nav-item"]} side-navbar__nav-item`}>
                    <NavLink to='/' className={styles["side-navbar__nav-link"]}>{t('home')}</NavLink>
                </li>
                <li className={`${styles["side-navbar__nav-item"]} side-navbar__nav-item`}>
                    <NavLink to='/about' className={styles["side-navbar__nav-link"]}>{t('about_minstry')}</NavLink>
                </li>
                <li className={`${styles["side-navbar__nav-item"]} side-navbar__nav-item`}>
                    <NavLink to='/media' className={styles["side-navbar__nav-link"]}>{t('media_center')}</NavLink>
                </li>
                <li className={`${styles["side-navbar__nav-item"]} side-navbar__nav-item`}>
                    <NavLink to='/electronic' className={styles["side-navbar__nav-link"]}>{t('electronic_services')}</NavLink>
                </li>
                <li className={`${styles["side-navbar__nav-item"]} side-navbar__nav-item`}>
                    <NavLink to='/jobs' className={styles["side-navbar__nav-link"]}>{t('jobs')}</NavLink>
                </li>
            </ul>
        </div>
        <div className={styles["side-navbar__overlayer"]} onClick={()=>{toggleSideNavBar('close')}}></div>
    </nav>
  )
}

export default SideBar